<template>

  <FocusTrap>
    <!-- Basic layout-->
    <div class="card" id="mycard">
      <div class="card-header header-elements-inline">
        <h5 class="card-title">Price Update</h5>
        <div class="header-elements">
          <div class="list-icons">
            <a class="list-icons-item" data-action="reload" @click="loadData" ></a>
            <a class="list-icons-item" data-action="remove" @click="closeWindow" ></a>
          </div>
        </div>
      </div>

      <div class="card-body">
        <div >

          <div class="form-group row">
            <label class="col-lg-3 col-form-label font-weight-semibold ">Petrol:</label>
            <div class="col-lg-9">
              <input id="txtpetrol" type="number" class="form-control" placeholder="Petrol Price" v-model="petrol101" autofocus >
            </div>
          </div>

          <div class="form-group row">
            <label class="col-lg-3 col-form-label font-weight-semibold ">Diesel:</label>
            <div class="col-lg-9">
              <input type="number" class="form-control" placeholder="Diesel Price" v-model="diesel102">
            </div>
          </div>

          <div class="form-group row">
            <label class="col-lg-3 col-form-label font-weight-semibold ">Speed:</label>
            <div class="col-lg-9">
              <input type="number" class="form-control" placeholder="Speed Price" v-model="speed103" >
            </div>
          </div>

          <div class="form-group row">
            <label class="col-lg-3 col-form-label font-weight-semibold ">2T Oil Loose:</label>
            <div class="col-lg-9">
              <input type="number" class="form-control" placeholder="2T Oil" v-model="oil104" >
            </div>
          </div>


          <div class="form-group row" v-for="itm in others" >
            <label class="col-lg-3 col-form-label font-weight-semibold ">{{itm.name}}</label>
            <div class="col-lg-5">
              <input type="number" class="form-control text-right"  placeholder="Sale Rate" v-model="itm.salerate" >
            </div>

            <div class="col-lg-4">
              <input type="number" class="form-control text-right" placeholder="Print Order" v-model="itm.porder"  >
            </div>
          </div>


          <div class="text-right">
            <button type="submit" class="btn btn-outline-success legitRipple" @click="perform_save_action" >Save <i class="icon-database-edit2 ml-2"></i></button>
          </div>


        </div>
      </div>
    </div>
    <!-- /basic layout -->
  </FocusTrap>
</template>

<script>
  import moment from 'moment-timezone';
  import { userService } from '@/store/auth-header.js';
  import { store } from '@/store/store.js';

  export default {
    name: 'PriceUpdateForm',
    store,
    data(){
      return {
        petrol101: 0,
        diesel102: 0,
        speed103: 0,
        oil104:0,
        others: [],
        items:[],
      }
    },
    mounted () {
      const self = this;
      this.loadData();
      $('#txtpetrol').focus();
    },
    methods:{
      closeWindow(){
        this.$router.push('/');
      },
      loadData () {
        const self = this
        const requestOptions = {
          method: 'GET',
          mode: 'cors',
          headers: userService.authHeader()
        }

        $('#mycard').block({
          msg: '<i class="icon-spinner2 spinner"></i>',
          overlayCSS: {
            backgroundColor: '#fff',
            opacity: 0.8,
            cursor: 'wait',
            'box-shadow': '0 0 0 1px #ddd'
          },
          css: {
            border: 0,
            padding: 0,
            backgroundColor: 'none'
          }
        });

        self.$data.items = [];
        fetch(`${process.env.VUE_APP_ROOT_API}api/xpos/items/after/999`, requestOptions).then(userService.handleResponse).then(function (resp) {

          if (resp.ok) {
            if (_.isArray(resp.data)) {
              self.$data.items = resp.data;

              self.$data.items.forEach(function (itm) {
                switch (itm.id) {
                  case 101:
                    self.$data.petrol101 = itm.salerate;
                    break;
                  case 102:
                    self.$data.diesel102 = itm.salerate;
                    break;
                  case 103:
                    self.$data.speed103 = itm.salerate;
                    break;
                  case 104:
                    self.$data.oil104 = itm.salerate;
                    break;
                  default:
                    self.$data.others.push(itm);
                }
              });

            }
          } else {
            swal({ title: 'Oops!', text: resp.msg, type: 'error' })
          }
        }).catch(function (err) {
          swal({ title: 'Oh noes!', text: err.toString(), type: 'error' })
        }).finally(function () {
          $('#mycard').unblock()
        })
      },
      perform_save_action () {
        const self = this;



        self.$data.others.forEach(function (itm) {
          itm.salerate = parseFloat(itm.salerate);
            itm.porder = parseInt(itm.porder);
            if(itm.porder < 5 ) {
              itm.porder = 999;
            }
        });

        $('#mycard').block({
          msg: '<i class="icon-spinner2 spinner"></i>',
          overlayCSS: {
            backgroundColor: '#fff',
            opacity: 0.8,
            cursor: 'wait',
            'box-shadow': '0 0 0 1px #ddd'
          },
          css: {
            border: 0,
            padding: 0,
            backgroundColor: 'none'
          }
        });


        let data = [];
        data.push({
          "item_id" : 101,
          "salerate" : parseFloat(self.$data.petrol101)
        });

        data.push({
          "item_id" : 102,
          "salerate" : parseFloat(self.$data.diesel102)
        });

        data.push({
          "item_id" : 103,
          "salerate" : parseFloat(self.$data.speed103)
        });

        data.push({
          "item_id" : 104,
          "salerate" : parseFloat(self.$data.oil104)
        });

        // let data = {'101': parseFloat(self.$data.petrol101), '102': parseFloat(self.$data.diesel102),'103': parseFloat(self.$data.speed103), '104': parseFloat(self.$data.oil104), 'others': self.$data.others };
        // let data = {'others': self.$data.others};

        // console.log(JSON.stringify(data));

        const requestOptions = {
          method: 'PUT',
          mode: 'cors',
          headers: userService.authHeader(),
          body: JSON.stringify(data)
        }

        // alert(`${process.env.VUE_APP_ROOT_API}api/xpos/item/priceupdate`);
        fetch(`${process.env.VUE_APP_ROOT_API}api/xpos/item/priceupdate`, requestOptions).then(userService.handleResponse).then(function (resp) {

          if (resp.ok) {
              swal({ title: 'success', text: resp.msg, type: 'success' });
          } else {
            swal({ title: 'Oops!', text: resp.msg, type: 'error' })
          }
        }).catch(function (err) {
          swal({ title: 'Oh noes!', text: err.toString(), type: 'error' })
        }).finally(function () {
          $('#mycard').unblock()
        });

      },

    }
  }
</script>

<style scoped>
  input:focus {
    background: #feff00;

  }

  textarea:focus {
    background: #feff00;

  }

  select:focus {
    background: #feff00;
  }

  button:focus {
    background: #feff00;
    color: #0a0a0a!important;
  }
</style>
